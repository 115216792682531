import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import VisibilitySensor from "react-visibility-sensor";

import { ScreenWidthContext, FontLoadedContext } from "../../layouts";
import config from "../../../content/meta/config";
import Menu from "../Menu";

import avatar from "../../images/jpg/avatar.jpg";

class Header extends React.Component {
  state = {
    fixed: false
  };

  visibilitySensorChange = val => {
    if (val) {
      this.setState({ fixed: false });
    } else {
      this.setState({ fixed: true });
    }
  };

  getHeaderSize = () => {
    const fixed = this.state.fixed ? "fixed" : "";
    const homepage = this.props.path === "/" ? "homepage" : "";

    return `${fixed} ${homepage}`;
  };

  render() {
    const { pages, path, theme } = this.props;
    const { fixed } = this.state;

    return (
      <React.Fragment>
        <header className={`header ${this.getHeaderSize()}`}>
          <Link to="/" className="logoType">
            <div className="logo">
              <img src={config.gravatarImgMd5=="" ? avatar : config.gravatarImgMd5 } alt={config.siteTitle} />
            </div>
            <div className="type">
              <h1>{config.headerTitle}</h1>
              <h2>{config.headerSubTitle}</h2>
            </div>
          </Link>
          <FontLoadedContext.Consumer>
            {loaded => (
              <ScreenWidthContext.Consumer>
                {width => (
                  <Menu
                    path={path}
                    fixed={fixed}
                    screenWidth={width}
                    fontLoaded={loaded}
                    pages={pages}
                    theme={theme}
                  />
                )}
              </ScreenWidthContext.Consumer>
            )}
          </FontLoadedContext.Consumer>
          <div className="social">
            <a href="https://www.facebook.com/ParadigmShiftSeminar1/" target="_blank" rel="noopener noreferrer">
              <svg className="niftybutton-facebook"  data-tag="fac" data-name="Facebook" viewBox="0 0 512 512" preserveAspectRatio="xMidYMid meet">
                <path d="M211.9 197.4h-36.7v59.9h36.7V433.1h70.5V256.5h49.2l5.2-59.1h-54.4c0 0 0-22.1 0-33.7 0-13.9 2.8-19.5 16.3-19.5 10.9 0 38.2 0 38.2 0V82.9c0 0-40.2 0-48.8 0 -52.5 0-76.1 23.1-76.1 67.3C211.9 188.8 211.9 197.4 211.9 197.4z"></path>
              </svg>
            </a>
            <a href="https://www.instagram.com/mystudycommunity" target="_blank" rel="noopener noreferrer" >
              <svg className="niftybutton-instagram"  data-tag="ins" data-name="Instagram" viewBox="0 0 512 512" preserveAspectRatio="xMidYMid meet">
                <path d="M256 109.3c47.8 0 53.4 0.2 72.3 1 17.4 0.8 26.9 3.7 33.2 6.2 8.4 3.2 14.3 7.1 20.6 13.4 6.3 6.3 10.1 12.2 13.4 20.6 2.5 6.3 5.4 15.8 6.2 33.2 0.9 18.9 1 24.5 1 72.3s-0.2 53.4-1 72.3c-0.8 17.4-3.7 26.9-6.2 33.2 -3.2 8.4-7.1 14.3-13.4 20.6 -6.3 6.3-12.2 10.1-20.6 13.4 -6.3 2.5-15.8 5.4-33.2 6.2 -18.9 0.9-24.5 1-72.3 1s-53.4-0.2-72.3-1c-17.4-0.8-26.9-3.7-33.2-6.2 -8.4-3.2-14.3-7.1-20.6-13.4 -6.3-6.3-10.1-12.2-13.4-20.6 -2.5-6.3-5.4-15.8-6.2-33.2 -0.9-18.9-1-24.5-1-72.3s0.2-53.4 1-72.3c0.8-17.4 3.7-26.9 6.2-33.2 3.2-8.4 7.1-14.3 13.4-20.6 6.3-6.3 12.2-10.1 20.6-13.4 6.3-2.5 15.8-5.4 33.2-6.2C202.6 109.5 208.2 109.3 256 109.3M256 77.1c-48.6 0-54.7 0.2-73.8 1.1 -19 0.9-32.1 3.9-43.4 8.3 -11.8 4.6-21.7 10.7-31.7 20.6 -9.9 9.9-16.1 19.9-20.6 31.7 -4.4 11.4-7.4 24.4-8.3 43.4 -0.9 19.1-1.1 25.2-1.1 73.8 0 48.6 0.2 54.7 1.1 73.8 0.9 19 3.9 32.1 8.3 43.4 4.6 11.8 10.7 21.7 20.6 31.7 9.9 9.9 19.9 16.1 31.7 20.6 11.4 4.4 24.4 7.4 43.4 8.3 19.1 0.9 25.2 1.1 73.8 1.1s54.7-0.2 73.8-1.1c19-0.9 32.1-3.9 43.4-8.3 11.8-4.6 21.7-10.7 31.7-20.6 9.9-9.9 16.1-19.9 20.6-31.7 4.4-11.4 7.4-24.4 8.3-43.4 0.9-19.1 1.1-25.2 1.1-73.8s-0.2-54.7-1.1-73.8c-0.9-19-3.9-32.1-8.3-43.4 -4.6-11.8-10.7-21.7-20.6-31.7 -9.9-9.9-19.9-16.1-31.7-20.6 -11.4-4.4-24.4-7.4-43.4-8.3C310.7 77.3 304.6 77.1 256 77.1L256 77.1z"></path>
                <path d="M256 164.1c-50.7 0-91.9 41.1-91.9 91.9s41.1 91.9 91.9 91.9 91.9-41.1 91.9-91.9S306.7 164.1 256 164.1zM256 315.6c-32.9 0-59.6-26.7-59.6-59.6s26.7-59.6 59.6-59.6 59.6 26.7 59.6 59.6S288.9 315.6 256 315.6z"></path>
                <circle cx="351.5" cy="160.5" r="21.5"></circle>
              </svg>
            </a>
            {/* Obtained from social-icon-generator.com */}
            <a href="https://www.tiktok.com/@mystudycommunity" target="_blank" rel="noopener noreferrer" >
              <svg className="niftybutton-instagram"  data-tag="ins" data-name="Instagram" viewBox="3 0 45 45" preserveAspectRatio="xMidYMid meet">
                <path d="M24.7 10v19.14a4.05 4.05 0 01-7.28 2.44 4.05 4.05 0 014.46-6.32v-4.9a8.86 8.86 0 00-7.55 14.97 8.86 8.86 0 0015.2-6.2v-9.77a11.46 11.46 0 006.67 2.14v-4.8a6.76 6.76 0 01-4.95-2.17 6.68 6.68 0 01-1.73-3.91V10H28.2z" clip-rule="evenodd" fill-rule="evenodd"/>
              </svg>
            </a>
            {/* Obtained from social-icon-generator.com */}
            <a href="https://www.youtube.com/watch?v=G4zQ1VLzAHw&list=PLrIzGv9OlZl8Jhqt1-32ZDTio6p6CA8BT" target="_blank" rel="noopener noreferrer" >
              <svg  data-tag="ins" data-name="Instagram" viewBox="9 6 32 32" preserveAspectRatio="xMidYMid meet" >
                  <path d="M24 14.2s-8.76 0-10.94.59a3.5 3.5 0 00-2.48 2.47C10 19.44 10 24 10 24s0 4.56.58 6.74a3.5 3.5 0 002.48 2.48c2.18.58 10.94.58 10.94.58s8.75 0 10.94-.58a3.5 3.5 0 002.47-2.48C38 28.56 38 24 38 24s0-4.56-.59-6.74a3.5 3.5 0 00-2.47-2.47C32.75 14.2 24 14.2 24 14.2zm-2.8 5.6l7.27 4.2-7.27 4.2z"></path>
              </svg>
            </a>
            {/* Inspired by from https://icon-icons.com/icon/email-envelope-outline-shape-with-rounded-corners/56530 */}
            <a href="mailto:info@mystudycommunity.com">
              <svg class="svg-icon" viewBox="-25 -140 650 650">
                  <path d="M 85.81055,0 C 38.70442,0 0,38.70442 0,85.81055 V 348.19141 C 0,395.29754 38.70442,434 85.81055,434 H 476.18946 C 523.29559,434 562,395.29754 562,348.19141 V 85.81055 C 562,38.70442 523.29559,0 476.18946,0 Z m 7.41602,50 H 468.77344 L 324.86914,171.31836 281,208.30078 237.13086,171.31836 Z M 50.60938,79.47071 185.79688,193.43946 50.55274,353.94336 C 50.26604,352.06287 50,350.17237 50,348.19141 V 85.81055 c 0,-2.18286 0.26251,-4.28023 0.60938,-6.33984 z m 460.78125,0 C 511.7375,81.53032 512,83.62769 512,85.81055 v 262.38086 c 0,1.98096 -0.26603,3.87146 -0.55273,5.75195 L 376.20313,193.43946 Z m -287.36524,146.19531 40.86133,34.44726 a 25.0025,25.0025 0 0 0 32.22656,0 L 337.97461,225.66602 471.38867,384 H 90.61133 Z"></path>
              </svg>
            </a>

          </div>
        </header>
        <VisibilitySensor onChange={this.visibilitySensorChange}>
          <div className="sensor" />
        </VisibilitySensor>

        {/* --- STYLES --- */}
        <style jsx>{`
          .social{
            display: flex;
            justify-content: center;
            z-index: 10;
            
            @from-width tablet {
              padding-bottom: 0.5em;
              margin-left: 1em;
            }
            @from-width desktop {
              padding-bottom: 0.5em;
              margin-left: 1em;
            }
            a + a + a + a + a {
              display: none;
              @from-width tablet { display: unset };
              @from-width desktop { display: unset };
            }
          }

          .social a  {
            text-decoration: none;
            border: 0;
            width: 2em;
            height: 2em;
            margin: 0 0.3em;
            color: ${theme.text.color.primary};
            border-radius: 15%;
          }
          .homepage:not(.fixed) .social a {
            color: ${theme.text.color.primary};
          }
          .social svg {
            display:block;
            fill:currentColor;
          }
          .social a:hover {
            background-color: hsla(0, 0%, 80%, 30%);
          }
          .header {
            align-items: center;
            justify-content: center;
            background-color: ${theme.color.neutral.white};
            display: flex;
            height: ${theme.header.height.default};
            position: relative;
            top: 0;
            width: 100%;
            align-items: center;
            box-shadow: 0 1px 2px hsla(0, 0%, 0%, 20%);

            :global(a.logoType) {
              align-items: center;
              display: flex;
              flex-direction: "column";
              color: ${theme.text.color.primary};

              .logo {
                flex-shrink: 0;
              }
            }

            &.homepage {
              position: absolute;
              background-color: transparent;
              height: ${theme.header.height.homepage};
            }
          }

          .type {
            display: flex;
            flex-direction: column;
          }
          h1 {
            font-size: ${theme.font.size.m};
            font-weight: ${theme.font.weight.standard};
            margin: ${theme.space.stack.xs};
          }

          h2 {
            font-weight: ${theme.font.weight.standard};
            font-size: ${theme.font.size.xxs};
            letter-spacing: 0;
            margin: 0;
            display: none;
            @from-width tablet {
              display: unset;
            }
            @from-width desktop {
              display: unset;
            }
          }

          .logo {
            border-radius: 65% 75%;
            border: 1px solid #eee;
            display: inline-block;
            height: 44px;
            margin: ${theme.space.inline.default};
            overflow: hidden;
            width: 44px;
            transition: all 0.5s;

            .homepage & {
              height: 60px;
              width: 60px;
            }

            img {
              width: 100%;
            }
          }

          .sensor {
            display: block;
            position: absolute;
            bottom: 0;
            z-index: 1;
            left: 0;
            right: 0;
            height: 1px;
            top: ${path === "/" ? theme.header.height.homepage : theme.header.height.default};
          }

          @from-width tablet {
            .header {
              padding: ${theme.space.inset.l};

              &.homepage {
                height: ${theme.header.height.homepage};
              }
            }
          }

          @below desktop {
            .header.homepage {
              .logo {
                border: none;
              }

              :global(a.logoType),
              h1 {
                color: ${theme.text.color.primary};
              }
              h2 {
                color: ${theme.color.neutral.gray.j};
              }
            }
          }

          @from-width desktop {
            .header {
              align-items: center;
              background-color: ${theme.color.neutral.white};
              display: flex;
              position: absolute;
              top: 0;
              width: 100%;
              justify-content: space-between;
              transition: padding 0.5s;

              &.fixed {
                height: ${theme.header.height.fixed};
                background-color: ${theme.color.neutral.white};
                left: 0;
                padding: 0 ${theme.space.m};
                position: fixed;
                top: 0;
                width: 100%;
                z-index: 1;

                h1 {
                  margin: ${theme.space.stack.xxs};
                }

                h2 {
                  display: none;
                }
              }

              &.homepage:not(.fixed) {
                :global(a.logoType),
                h1 {
                  color: ${theme.text.color.primary};
                }
                h2 {
                  color: ${theme.color.neutral.gray.j};
                }
              }
            }

            .header :global(a.logoType) {
              text-align: left;
              flex-direction: row;
              flex-shrink: 0;
              width: auto;
            }

            .logo {
              margin: ${theme.space.inline.default};

              .fixed & {
                height: 36px;
                width: 36px;
              }

              .header.homepage:not(.fixed) & {
                border: none;
              }
            }

            h2 {
              animation-duration: ${theme.time.duration.default};
              animation-name: h2Entry;
            }

            @keyframes h2Entry {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }
          }
        `}</style>
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  pages: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired
};

export default Header;
